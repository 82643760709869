import { Box, Button, Container, Typography } from "@mui/material";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch } from "src/hooks/stateHooks";
import { registerProp } from "src/lib/btracking";
import { setChosenOS } from "src/slices/surveySlice";

export default function Phone() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();

  const onClick = (os: "android" | "ios") => {
    registerProp({ mobileOS: os });
    dispatch(setChosenOS(os));
    survey.nextPage();
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 4 }}>
        What type of phone do you use?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Button
          sx={{
            mb: 2,
          }}
          onClick={() => {
            onClick("ios");
          }}
        >
          iPhone
        </Button>
        <Button
          sx={{
            mb: 2,
          }}
          onClick={() => {
            onClick("android");
          }}
        >
          Android
        </Button>
      </Box>
    </Container>
  );
}
