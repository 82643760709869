import { Box, Button, Container, Typography } from "@mui/material";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch } from "src/hooks/stateHooks";
import { setTrainerExperience } from "src/slices/surveySlice";

export default function PersonalTrainerExperience() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();

  const onClick = (choice: boolean) => {
    dispatch(setTrainerExperience(choice));
    survey.nextPage();
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 4 }}>
        Have you ever worked with a personal trainer before?
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
        }}
      >
        <Button
          sx={{
            mb: 2,
          }}
          onClick={() => {
            onClick(true);
          }}
        >
          Yes
        </Button>
        <Button
          sx={{
            mb: 2,
          }}
          onClick={() => {
            onClick(false);
          }}
        >
          No
        </Button>
      </Box>
    </Container>
  );
}
