import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { ProjectedInfluencer } from "@trainwell/features/influencers";

interface SplashPageTwoPanelProps {
  ctaCopy: string;
  influencer?: ProjectedInfluencer | undefined;
  onClick: () => void;
}

export default function SplashPage_BetterHelp({
  ctaCopy,
  influencer,
  onClick,
}: SplashPageTwoPanelProps) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div style={{ margin: 0, padding: 0, height: "100%" }}>
      <div
        style={{
          position: "relative",
          display: "grid",
          gap: isPhone ? "48px" : undefined,
          gridTemplateColumns: "100%",
          width: "100%",
          height: "100%",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          minHeight: "100vh",
          background: "#C8E3F9",
        }}
      >
        <div
          style={{
            order: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
            justifyContent: "start",
          }}
        >
          <div
            style={{
              maxWidth: isPhone ? "90%" : "80%",
            }}
          >
            <div
              style={{
                marginTop: isPhone ? "28px" : "64px",
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "30px",
              }}
            >
              <Typography
                // @ts-expect-error
                variant="YTBig"
                sx={{
                  textAlign: "center",
                  fontSize: isPhone ? 26 : 42,
                  fontWeight: 900,
                  lineHeight: 1,
                  color: "#443564",
                }}
              >
                Convenient and affordable training {!isPhone && <br />}
                with <span style={{ color: "#775fab" }}>trainwell</span>
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <img
                src={influencer?.assets.splash_asset}
                style={{
                  maxWidth: "60%",
                  maxHeight: isPhone ? "65%" : "300px",
                }}
              />
            </div>

            <Typography
              variant="body2"
              sx={{
                textAlign: "center",
                fontSize: isPhone ? 18 : 22,

                marginTop: "16px",
              }}
            >
              Welcome{" "}
              <span style={{ fontWeight: "bold" }}>
                {influencer?.display_name}
              </span>{" "}
              visitors! Get started today and enjoy a{" "}
              <span style={{ fontWeight: "bold" }}>14 day free trial.</span>
            </Typography>
            <Button
              style={{
                marginTop: isPhone ? "2em" : "3em",
                marginBottom: isPhone ? "4em" : "2em",
                height: "55px",
                width: "100%",
                borderRadius: "8px",
              }}
              onClick={onClick}
            >
              {ctaCopy}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
