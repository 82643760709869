import type { SubscriptionGroupOption } from "@trainwell/features/subscription-groups";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { setCookie } from "src/lib/cookie";
import * as fbq from "src/lib/fpixel";
import { sha1Hash } from "src/lib/misc";
import { api } from "src/lib/trainwellApi";
import { setForceTrainerId } from "src/slices/coachSlice";
import { resetPagePaths } from "src/slices/navigationSlice";
import { setCodeInfo } from "src/slices/paymentSlice";
import AppProviders from "./AppProviders";
import AppRoutes from "./AppRoutes";

export default function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const clientId = useAppSelector((state) => state.client.clientID);
  const email = useAppSelector((state) => state.survey.email);

  useEffect(() => {
    if (clientId) {
      setCookie("userID", clientId);
    }
  }, [clientId]);

  useEffect(() => {
    dispatch(resetPagePaths());
  }, []);

  useEffect(() => {
    fbq.pageview();
    fbq.event("ViewContent");
    window.gtag("set", "page", location.pathname);
    // @ts-expect-error
    window.gtag("send", "pageview");
  });

  useEffect(() => {
    if (clientId) {
      //IMPACT.COM identify event (waits for userId / email)
      const impactBody = {
        customerId: clientId ?? "",
        customerEmail: email !== undefined ? sha1Hash(email) : "",
        customProfileId: clientId ?? "",
      };
      // @ts-expect-error
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      window.ire("identify", impactBody);
    }
  }, [clientId, email, location]);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code_id = urlParams.get("code");

    if (code_id) {
      api.discountCodes.getOne(code_id).then((code) => {
        dispatch(
          setCodeInfo({
            codeID: code.code_id,
            overrideTrialDays: code.override_trial_days
              ? Number(code.override_trial_days)
              : undefined,
            overrideSubscriptionOptions:
              code.subscription_options as SubscriptionGroupOption[],
          }),
        );
      });
    }

    const forcedTrainerID = urlParams.get("trainer_id");
    dispatch(setForceTrainerId(forcedTrainerID ? forcedTrainerID : undefined));
  }, []);

  return (
    <AppProviders>
      <AppRoutes />
    </AppProviders>
  );
}
