import SwapHorizRoundedIcon from "@mui/icons-material/SwapHorizRounded";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Colander } from "@trainwell/colander";
import { useEffect } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { api } from "src/lib/trainwellApi";
import { selectActiveTests, trackEvent } from "src/slices/analyticsSlice";
import { resetSelectedAvailability } from "src/slices/coachSlice";

export default function ScheduleCall() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const theme = useTheme();
  const user_id = useAppSelector((state) => state.client.clientID);
  const trainer = useAppSelector((state) => state.coach.selectedTrainer);
  const activeTestsIds = useAppSelector(selectActiveTests);

  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const queryParamSkipCall = Boolean(
      JSON.parse(urlParams.get("skip_call") ?? "false") ?? false,
    );

    if (queryParamSkipCall) {
      survey.nextPage();
    }
  });

  // Unbounce Conversion Tracking
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//try.trainwell.net/_ub/static/ets/t.js";
    document.body.appendChild(script);

    // @ts-expect-error
    const _ubaq = window._ubaq || [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    _ubaq.push(["trackGoal"]);
    // @ts-expect-error
    window._ubaq = _ubaq;

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  if (!user_id || !trainer) {
    return <Typography>Something went wrong</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box sx={{ px: 2 }}>
        <Typography variant="h1" sx={{ mb: 4 }}>
          Schedule Your First Call
        </Typography>
        {/* Kate gets special 60 min calls cause of CPT2 */}
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          {`Please don't be late to your call, ${trainer?.first_name} booked ${trainer.trainer_id === "810a6c50-dad6-49b5-860b-0f27d007911e" ? "60" : "40"} minutes in their busy schedule to get to know you!`}
        </Typography>
      </Box>
      {trainer ? (
        <Container maxWidth="md" sx={{ py: 4 }}>
          <Colander
            variant={isSmall ? "box" : "card"}
            coach={trainer}
            userId={user_id}
            disableCallTypeSelection
            disableCheckinToggle
            defaultCallType={"onboarding"}
            onFinishBooking={() => {
              track("Onboarding Call Scheduled");
              dispatch(resetSelectedAvailability());
              survey.nextPage();
            }}
            api={api}
            onPickedAvailabilityFail={() => {
              dispatch(resetSelectedAvailability());
            }}
            enableAvailabilityApology={activeTestsIds.includes(
              "availability_apology_treatment",
            )}
          />
        </Container>
      ) : (
        <CircularProgress />
      )}
      {activeTestsIds.includes("jan_ob_offer_change_trainers_treatment") && (
        <Card
          variant="outlined"
          sx={{
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
            p: 1,
            my: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
            <SwapHorizRoundedIcon color="primary" sx={{ mr: 1 }} />
            <Typography>Want a better call time? Switch trainers!</Typography>
          </Box>
          <Button
            size="small"
            href={`https://account.trainwell.net/change-coach?user_id=${user_id}`}
            fullWidth={isSmall}
            onClick={() => {
              dispatch(
                trackEvent({
                  event_type: "ob_call_switch_trainer_clicked",
                  event_content: {
                    location: "onboarding",
                  },
                }),
              );
            }}
            sx={{ py: 0.5 }}
          >
            See more amazing trainers
          </Button>
        </Card>
      )}
      <img
        src={`https://www.shareasale.com/sale.cfm?tracking=${user_id}&amount=99.00&merchantID=106416&transtype=sale`}
        width="1"
        height="1"
      />
      {/* Afiliate marketing */}
      <script
        src="https://www.dwin1.com/19038.js"
        type="text/javascript"
        defer
      ></script>
    </Box>
  );
}
