import {
  Avatar,
  Box,
  Button,
  Stack,
  Typography,
  type StackProps,
} from "@mui/material";
import type { Trainer } from "@trainwell/features/legacy";

interface SplashPageForcedTrainerProps {
  trainer: Trainer;
  onClick: () => void;
}

const IconAndProof = ({ sx, ...rest }: StackProps) => (
  <Stack
    flexDirection="column"
    sx={{ gap: { xs: "10px", md: "16px" }, ...sx }}
    {...rest}
  >
    <Box
      component="img"
      src="/assets/trainwell-wordmark.svg"
      sx={{ height: { xs: "20px", md: "24px" } }}
    />
    <Box
      component="img"
      src="/assets/forbes-proof.svg"
      sx={{ height: { xs: "10px", md: "14px" }, opacity: "0.66" }}
    />
  </Stack>
);

const DecorativeDots = ({ sx, ...rest }: StackProps) => (
  <Stack flexDirection="row" sx={{ opacity: "0.75", ...sx }} {...rest}>
    <Box
      component="img"
      src="/assets/branded-dots.png"
      sx={{
        width: "50%",
        aspectRatio: "1.0",
      }}
    />
    <Box
      component="img"
      src="/assets/branded-dots.png"
      sx={{
        width: "50%",
        aspectRatio: "1.0",
        transform: "scaleX(-1)",
      }}
    />
  </Stack>
);

const TopHStack = ({ trainer }: { trainer: Trainer }) => (
  <Stack flexDirection="row" alignItems="center" gap="32px" width="fit-content">
    <Avatar
      src={trainer.headshot_url_hd}
      sx={{
        width: { xs: "120px", md: "180px" },
        height: { xs: "120px", md: "180px" },
      }}
    />
    <Stack flexDirection="column" gap="20px">
      <Typography
        // @ts-expect-error
        variant="YTBig"
        sx={{
          fontSize: { xs: 32, md: 36 },
          textAlign: "center",
          fontWeight: "bold",
          lineHeight: 1,
          color: "#443564",
        }}
      >
        {trainer.first_name + " " + trainer.last_name.charAt(0) + "."}
      </Typography>
      <Stack flexDirection="row" alignItems="center" gap="8px">
        <Typography
          sx={{
            fontSize: { xs: 20, md: 24 },
            fontWeight: "bold",
            lineHeight: 1,
            color: "#443564",
          }}
        >
          ★★★★★
        </Typography>
        {trainer.n_reviews && (
          <Typography
            sx={{
              fontSize: { xs: 12, md: 14 },
              fontWeight: "bold",
              lineHeight: 1,
              color: "#443564",
            }}
          >
            {trainer.n_reviews || -1} ratings
          </Typography>
        )}
      </Stack>
    </Stack>
  </Stack>
);

const MidVStack = () => (
  <Stack flexDirection="column" alignItems="center" gap="20px">
    <Typography
      sx={{
        fontSize: { xs: 24, md: 26 },
        fontWeight: 900,
        textAlign: "center",
        lineHeight: 1,
        color: "#443564",
      }}
    >
      Start your fitness journey!
    </Typography>
    <Typography
      sx={{
        fontSize: { xs: 18, md: 20 },
        fontWeight: 300,
        textAlign: "center",
        lineHeight: "140%",
        color: "#443564",
      }}
    >
      We&apos;ll get started by asking you a few questions to tailor your
      experience
    </Typography>
  </Stack>
);

export default function SplashPage_ForcedTrainer({
  trainer,
  onClick,
}: SplashPageForcedTrainerProps) {
  return (
    <Box
      sx={{
        position: "absolute",
        inset: "0px",
        m: "0px",
        p: "0px",
        overflowX: "hidden",
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          position: "relative",
          height: "100%",
          m: "0px",
          p: "16px",
          bgcolor: "#C8E3F9",
          overflowX: "hidden",
        }}
      >
        <IconAndProof
          sx={{
            position: "absolute",
            top: "40px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
        <DecorativeDots
          sx={{
            position: "absolute",
            bottom: "0px",
            left: "0px",
            right: "0px",
          }}
        />
        <Stack
          flexDirection="column"
          alignItems="center"
          sx={{
            padding: { xs: "32px", md: "40px" },
            bgcolor: "white",
            width: "800px",
            maxWidth: "100%",
            gap: "48px",
            boxShadow: "0px 32px 96px -40px rgba(68, 53, 100, 0.33)",
            border: "1px solid rgba(65, 55, 96, 0.05)",
            borderRadius: "24px",
            zIndex: 1,
          }}
        >
          <TopHStack trainer={trainer} />
          <MidVStack />
          <Button
            sx={{
              height: "55px",
              width: "100%",
              borderRadius: "8px",
            }}
            onClick={onClick}
          >
            {`Partner with ${trainer.first_name}`}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
