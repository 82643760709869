import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import { Box, Container, IconButton, LinearProgress } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSurvey } from "src/components/Survey";
import { useAppSelector } from "src/hooks/stateHooks";

interface Props {
  value: number;
}

export default function Progress({ value }: Props) {
  const pagePaths = useAppSelector((state) => state.navigation.pagePaths);
  const survey = useSurvey();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const submitPaymentStatus = useAppSelector(
    (state) => state.payment.submitPaymentStatus,
  );

  function handleBack() {
    survey.previousPage();
  }

  const activePagePaths = pagePaths.filter((page) => page.active === true);

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        alignItems: "center",
        opacity: pagePaths[value]?.hideNavigation ? 0 : 1,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <IconButton
          size={isPhone ? "small" : undefined}
          aria-label="back"
          onClick={handleBack}
          disabled={value === 0 || submitPaymentStatus !== "idle"}
          sx={{
            opacity: value === 0 ? 0 : undefined,
          }}
        >
          <ArrowBackIosNewRoundedIcon fontSize="inherit" />
        </IconButton>
      </Box>
      <Box sx={{ width: "100%", px: 1 }}>
        <LinearProgress
          variant="determinate"
          value={Math.min(value / (activePagePaths.length - 6), 1) * 100}
        />
      </Box>
      <Box sx={{ flex: 1 }} />
    </Container>
  );
}
