import { Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch } from "src/hooks/stateHooks";
import { setWeightSystem } from "src/slices/surveySlice";

export default function WeightSystem() {
  const dispatch = useAppDispatch();
  const survey = useSurvey();

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 4 }}>
        Which units do you use?
      </Typography>
      <Grid container spacing={2}>
        <Grid size={12}>
          <Button
            fullWidth
            onClick={() => {
              dispatch(setWeightSystem("imperial"));
              survey.nextPage();
            }}
          >
            Pounds, feet, inches
          </Button>
        </Grid>
        <Grid size={12}>
          <Button
            fullWidth
            onClick={() => {
              dispatch(setWeightSystem("metric"));
              survey.nextPage();
            }}
          >
            Kilograms, meters, centimeters
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
