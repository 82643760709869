import type { PayloadAction } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type {
  OrganizationLocation,
  TagAddOn,
  TagExperience,
  TagGoal,
  TagIdentity,
  TagObWindowsPreferred,
  TagSpecialty,
} from "@trainwell/features/legacy";
import { goalCopy } from "src/components/pages/Tags/data/challengeData";
import { trackSelect, trackUnselect } from "src/lib/btracking";
import { api } from "src/lib/trainwellApi";
import { setPagePathStatus } from "./navigationSlice";
import type { RootState } from "./store";

export const shouldShowIdentity = createAsyncThunk(
  "survey/shouldShowIdentity",
  async (_, { dispatch }) => {
    const response = await api.clients
      .isIdentityAvailable()
      .then((data) => {
        if (data.gender_availability) {
          dispatch(setPagePathStatus({ path: "/tags1", status: "active" }));
          return true;
        }
        dispatch(setPagePathStatus({ path: "/tags1", status: "inactive" }));
        return false;
      })
      .catch(() => {
        dispatch(setPagePathStatus({ path: "/tags1", status: "inactive" }));
        return false;
      });

    return response;
  },
);

export interface SurveyInterface {
  email?: string;

  allowPickingGender: boolean;

  firstName?: string;
  lastName?: string;
  phoneNumber?: string;

  gender?: string;
  height?: number;
  weight?: number;
  age?: number;
  trainerExperience?: boolean;
  activityFrequency?:
    | "not_at_all"
    | "few_month"
    | "once_week"
    | "2_to_3_week"
    | "4_plus_week";
  workoutFrequency?:
    | "not_at_all"
    | "few_month"
    | "once_week"
    | "2_to_3_week"
    | "4_plus_week";
  workoutEquipment?:
    | "none"
    | "few_at_home"
    | "apartment_gym"
    | "full_gym_access";
  preferred_weight_system?: "imperial" | "metric";

  chosenPhoneOS?: "android" | "ios";
  ownsSmartWatch?: boolean;

  user_provided_sournce?: string;

  tagExperience: TagExperience[];
  tagSpecializations: TagSpecialty[];
  tagDemographics: TagIdentity[];
  tagCommunicationStyle: {
    key: string;
    value: number;
  }[];
  tagGoals: TagGoal[];
  tagAddOns: TagAddOn[];
  tagObWindowsPreferred: TagObWindowsPreferred[];

  organization_location_id?: string;
}

// Define the initial state using that type
const initialState: SurveyInterface = {
  allowPickingGender: true,
  tagCommunicationStyle: [],
  tagDemographics: [],
  tagExperience: [],
  tagSpecializations: [],
  tagGoals: [],
  tagAddOns: [],
  tagObWindowsPreferred: [],
};

export const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {
    resetSurvey: () => initialState,
    setWeightSystem: (state, action: PayloadAction<"imperial" | "metric">) => {
      state.preferred_weight_system = action.payload;
    },
    setWeight: (state, action: PayloadAction<number>) => {
      const weight = action.payload;

      state.weight = weight;
    },
    setHeight: (state, action: PayloadAction<number>) => {
      const height = action.payload;

      state.height = height;
    },
    setAge: (state, action: PayloadAction<number>) => {
      const age = action.payload;

      state.age = age;
    },
    setGender: (state, action: PayloadAction<string>) => {
      const gender = action.payload;

      state.gender = gender;
    },
    setFirstName: (state, action: PayloadAction<string>) => {
      const firstName = action.payload;

      state.firstName = firstName;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      const lastName = action.payload;

      state.lastName = lastName;
    },
    setPhoneNumber: (state, action: PayloadAction<string>) => {
      const phoneNumber = action.payload;

      state.phoneNumber = phoneNumber;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      const email = action.payload;

      state.email = email;
    },
    setOwnsSmartWatch: (state, action: PayloadAction<boolean>) => {
      const ownsSmartWatch = action.payload;

      state.ownsSmartWatch = ownsSmartWatch;
    },
    setTrainerExperience: (state, action: PayloadAction<boolean>) => {
      const trainerExperience = action.payload;

      state.trainerExperience = trainerExperience;
    },
    setWorkoutFrequency: (
      state,
      action: PayloadAction<SurveyInterface["workoutFrequency"]>,
    ) => {
      state.workoutFrequency = action.payload;
    },
    setActivityFrequency: (
      state,
      action: PayloadAction<SurveyInterface["activityFrequency"]>,
    ) => {
      state.activityFrequency = action.payload;
    },
    setWorkoutEquipment: (
      state,
      action: PayloadAction<SurveyInterface["workoutEquipment"]>,
    ) => {
      state.workoutEquipment = action.payload;
    },
    selectTagObWindowsPreferred: (
      state,
      action: PayloadAction<TagObWindowsPreferred>,
    ) => {
      const tag = action.payload;

      const index = state.tagObWindowsPreferred.indexOf(tag);

      if (index === -1) {
        if (tag === "none") {
          // Deselect all tags by selecting none
          trackSelect({ type: "ob_windows_preferred", tagSelected: "none" });
          state.tagObWindowsPreferred = [tag];
        } else {
          const noneIndex = state.tagObWindowsPreferred.indexOf("none");

          if (noneIndex !== -1) {
            // Deselect none this should not be tracked as they did not deselect none
            state.tagObWindowsPreferred.splice(noneIndex, 1);
          }
          // Select tag
          trackSelect({ type: "ob_windows_preferred", tagSelected: tag });
          state.tagObWindowsPreferred.push(tag);
        }
      } else {
        // Deselect tag
        trackUnselect({ type: "ob_windows_preferred", tagSelected: tag });
        state.tagObWindowsPreferred.splice(index, 1);
      }
    },
    setTagAddOns: (state, action: PayloadAction<TagAddOn[]>) => {
      state.tagAddOns = action.payload;
    },
    selectTagSpecialization: (state, action: PayloadAction<TagSpecialty>) => {
      const tag = action.payload;

      const index = state.tagSpecializations.indexOf(tag);

      if (index === -1) {
        if (tag === "none") {
          // Deselect all tags by selecting none
          trackSelect({ type: "specialization", tagSelected: "none" });
          state.tagSpecializations = [tag];
        } else {
          const noneIndex = state.tagSpecializations.indexOf("none");

          if (noneIndex !== -1) {
            // Deselect none this should not be tracked as they did not deselect none
            state.tagSpecializations.splice(noneIndex, 1);
          }
          // Select tag
          trackSelect({ type: "specialization", tagSelected: tag });
          state.tagSpecializations.push(tag);
        }
      } else {
        // Deselect tag
        trackUnselect({ type: "specialization", tagSelected: tag });
        state.tagSpecializations.splice(index, 1);
      }
    },
    selectTagExperience: (state, action: PayloadAction<TagExperience>) => {
      const tag = action.payload;

      const index = state.tagExperience.indexOf(tag);

      if (index === -1) {
        if (tag === "none") {
          // Deselect all tags by selecting none
          trackSelect({ type: "experience", tagSelected: "none" });
          state.tagExperience = [tag];
        } else {
          const noneIndex = state.tagExperience.indexOf("none");

          if (noneIndex !== -1) {
            // Deselect none this should not be tracked as they did not deselect none
            state.tagExperience.splice(noneIndex, 1);
          }

          // Select tag
          trackSelect({ type: "experience", tagSelected: tag });
          state.tagExperience.push(tag);
        }
      } else {
        // Deselect tag
        trackUnselect({ type: "experience", tagSelected: tag });
        state.tagExperience.splice(index, 1);
      }
    },
    selectTagDemographics: (state, action: PayloadAction<TagIdentity>) => {
      const tag = action.payload;
      // Same as above
      const index = state.tagDemographics.indexOf(tag);

      if (index === -1) {
        if (tag === "none") {
          trackSelect({ type: "demographics", tagSelected: "none" });
          state.tagDemographics = [tag];
        } else {
          const noneIndex = state.tagDemographics.indexOf("none");

          if (noneIndex !== -1) {
            state.tagDemographics.splice(noneIndex, 1);
          }

          trackSelect({ type: "demographics", tagSelected: tag });
          state.tagDemographics.push(tag);
        }
      } else {
        trackUnselect({ type: "demographics", tagSelected: tag });
        state.tagDemographics.splice(index, 1);
      }
    },
    setTagCommunicationStyle: (
      state,
      action: PayloadAction<{ key: string; value: number }>,
    ) => {
      const { key, value } = action.payload;

      const index = state.tagCommunicationStyle.findIndex(
        (item) => item.key === key,
      );

      if (index === -1) {
        // User selected value
        trackSelect({ type: "communication", tagSelected: key, value: value });
        state.tagCommunicationStyle.push({ key: key, value: value });
      } else {
        // Select new value
        trackSelect({ type: "communication", tagSelected: key, value: value });
        state.tagCommunicationStyle[index].value = value;
      }
    },
    setTagGoal: (state, action: PayloadAction<TagGoal>) => {
      const tag = action.payload;

      const goal = goalCopy.find((copy) => copy.key === tag);

      const index = state.tagGoals.indexOf(tag);

      if (index === -1) {
        // Select tag
        trackSelect({
          type: "tag_goal",
          tagSelected: tag,
        });
        state.tagGoals = [tag];

        //If this goal implies a specialty, add it to the specialties list
        if (goal?.specialtyKey) {
          state.tagSpecializations.push(goal.specialtyKey);
        }
      } else {
        // Deselect tag
        trackUnselect({
          type: "tag_goal",
          tagSelected: tag,
        });
        state.tagGoals = [];

        //If this goal implies a specialty, remove it from the specialties list
        if (goal?.specialtyKey) {
          const specialityIndex = state.tagSpecializations.indexOf(
            goal.specialtyKey,
          );
          state.tagSpecializations.splice(specialityIndex, 1);
        }
      }
    },
    setTagSpecialty: (state, action: PayloadAction<TagSpecialty[]>) => {
      state.tagSpecializations = action.payload;
    },
    setTagExperience: (state, action: PayloadAction<TagExperience[]>) => {
      state.tagExperience = action.payload;
    },
    setChosenOS: (state, action: PayloadAction<"android" | "ios">) => {
      state.chosenPhoneOS = action.payload;
    },
    setOrganizationLocationId: (
      state,
      action: PayloadAction<OrganizationLocation["_id"]>,
    ) => {
      state.organization_location_id = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(shouldShowIdentity.fulfilled, (state, action) => {
      state.allowPickingGender = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetSurvey,
  setWeightSystem,
  setWeight,
  setHeight,
  setAge,
  setGender,
  setFirstName,
  setLastName,
  setPhoneNumber,
  setEmail,
  setOwnsSmartWatch,
  selectTagSpecialization,
  setTagCommunicationStyle,
  selectTagDemographics,
  selectTagExperience,
  selectTagObWindowsPreferred,
  setTagGoal,
  setChosenOS,
  setWorkoutFrequency,
  setWorkoutEquipment,
  setActivityFrequency,
  setTrainerExperience,
  setTagSpecialty,
  setTagExperience,
  setTagAddOns,
  setOrganizationLocationId,
} = surveySlice.actions;

export default surveySlice.reducer;

export const selectFirstName = (state: RootState) => {
  return state.survey.firstName;
};

export const selectChosenOS = (state: RootState) => state.survey.chosenPhoneOS;

export const selectGoalTags = (state: RootState) => state.survey.tagGoals;

export const selectObWindowsPreferredTags = (state: RootState) =>
  state.survey.tagObWindowsPreferred;
