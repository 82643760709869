import {
  Button,
  CircularProgress,
  Container,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { selectActiveTests, trackEvent } from "src/slices/analyticsSlice";
import * as yup from "yup";

const validationSchema = yup.object({
  address1: yup.string().trim().required("Required"),
  address2: yup.string().trim().optional(),
  city: yup.string().required("Required"),
  state: yup.string().required("Required"),
  zipcode: yup
    .string()
    .required("Required")
    .matches(/^[0-9]+$/, "Must be only digits")
    .min(5, "Must be exactly 5 digits")
    .max(5, "Must be exactly 5 digits"),
  shirtSize: yup
    .string()
    .required("Required")
    .oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
});

const initialValues = {
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipcode: "",
  shirtSize: "",
};

export default function AddressContainer() {
  const dispatch = useAppDispatch();
  const phoneNumber = useAppSelector((state) => state.survey.phoneNumber);
  const survey = useSurvey();
  const activeTestsIds = useAppSelector(selectActiveTests);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    //If we don't think they are in the USA or not in the test, skip this page
    if (
      !phoneNumber ||
      !phoneNumber.startsWith("1") ||
      !activeTestsIds.includes("mar_gift_treatment")
    ) {
      survey.nextPage();
    }
  }, [phoneNumber, activeTestsIds]);

  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      dispatch(
        trackEvent({
          event_type: "address_submit",
          event_content: {
            ...values,
          },
        }),
      );
      track("address_submit");
      setLoading(true);
      setTimeout(() => {
        survey.nextPage();
      }, 700);
    },
  });

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h1"
        sx={{
          mb: 0,
          width: "100%",
          textAlign: "left",
        }}
      >
        We&apos;d love to send you a free trainwell gift!
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mt: 1,
          width: "100%",
          textAlign: "left",
        }}
      >
        U.S. Shipping only
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} sx={{ mb: 4, mt: 1 }}>
          <Grid size={12}>
            <TextField
              variant="outlined"
              label="Address 1"
              fullWidth
              color="primary"
              autoComplete="given-name"
              name="address1"
              value={formik.values.address1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address1 && Boolean(formik.errors.address1)}
              helperText={formik.touched.address1 && formik.errors.address1}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              variant="outlined"
              label="Address 2"
              fullWidth
              color="primary"
              autoComplete="address2"
              name="address2"
              value={formik.values.address2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.address2 && Boolean(formik.errors.address2)}
              helperText={formik.touched.address2 && formik.errors.address2}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              variant="outlined"
              label="City"
              fullWidth
              color="primary"
              autoComplete="city"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              variant="outlined"
              fullWidth
              color="primary"
              label="State"
              autoComplete="state"
              name="state"
              value={formik.values.state}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.state && Boolean(formik.errors.state)}
              helperText={formik.touched.state && formik.errors.state}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              variant="outlined"
              fullWidth
              color="primary"
              label="Zip Code"
              autoComplete="zipcode"
              name="zipcode"
              value={formik.values.zipcode}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
              helperText={formik.touched.zipcode && formik.errors.zipcode}
            />
          </Grid>
          <Grid size={6}>
            <TextField
              fullWidth
              color="primary"
              label="Shirt size"
              type="string"
              select
              name="shirtSize"
              value={formik.values.shirtSize}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={
                formik.touched.shirtSize && Boolean(formik.errors.shirtSize)
              }
              helperText={formik.touched.shirtSize && formik.errors.shirtSize}
            >
              {["xs", "sm", "md", "lg", "xl", "xxl"].map((size, idx) => (
                <MenuItem key={idx} value={size}>
                  {size}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        <Button type="submit" fullWidth disabled={loading}>
          {!loading ? (
            `Submit`
          ) : (
            <CircularProgress
              size={32}
              sx={{ color: "white" }}
            ></CircularProgress>
          )}
        </Button>
      </form>
    </Container>
  );
}
