import { Container, Typography, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useFormik } from "formik";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import {
  centimetersToInches,
  inchesToCentimeters,
  kilosToPounds,
  poundsToKilos,
} from "src/lib/misc";
import { setAge, setHeight, setWeight } from "src/slices/surveySlice";
import * as yup from "yup";
import CTAButton from "../Layout/CTAButton";

import TextFieldSelector from "./NativeSelector";

const validationImperialSchema = yup.object({
  heightFeet: yup
    .number()
    .min(0, "Must be more than 0")
    .positive("Must be positive")
    .integer("No decimal")
    .required("Required")
    .typeError("Must be a number"),
  heightInches: yup
    .number()
    .min(0, "Must be more than 0")
    .integer("No decimal")
    .required("Required")
    .typeError("Must be a number"),
  weight: yup
    .number()
    .positive("Must be positive")
    .integer("No decimal")
    .required("Required")
    .typeError("Must be a number"),
  age: yup
    .number()
    .positive("Must be positive")
    .integer("No decimal")
    .min(13, "Must be 13 years or older")
    .required("Required")
    .typeError("Must be a number"),
});

const validationMetricSchema = yup.object({
  heightCM: yup
    .number()
    .min(0, "Must be more than 0")
    .integer("No decimal")
    .required("Required")
    .typeError("Must be a number"),
  weight: yup
    .number()
    .positive("Must be positive")
    .integer("No decimal")
    .required("Required")
    .typeError("Must be a number"),
  age: yup
    .number()
    .positive("Must be positive")
    .integer("No decimal")
    .min(13, "Must be 13 years or older")
    .required("Required")
    .typeError("Must be a number"),
});

interface Values {
  heightCM: number | null;
  heightFeet: number | null;
  heightInches: number | null;
  weight: number | null;
  age: number | null;
}
export default function HeightWeightContainer() {
  const weightSystem =
    useAppSelector((state) => state.survey.preferred_weight_system) ??
    "imperial";
  const dispatch = useAppDispatch();
  const survey = useSurvey();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));
  const height = useAppSelector((state) => state.survey.height);
  const weight = useAppSelector((state) => state.survey.weight);
  const age = useAppSelector((state) => state.survey.age);

  const formik = useFormik({
    initialValues: {
      heightFeet: height ? Math.round((height - (height % 12)) / 12) : null,
      heightInches: height ? height % 12 : null,
      heightCM: height ? Math.round(inchesToCentimeters(height)) : null,
      weight: weight
        ? weightSystem === "imperial"
          ? Math.round(weight)
          : Math.round(poundsToKilos(weight))
        : null,
      age: age ?? null,
    },
    validateOnMount: true,
    validateOnBlur: true,
    validationSchema:
      weightSystem === "imperial"
        ? validationImperialSchema
        : validationMetricSchema,
    onSubmit: (values: Values) => {
      if (weightSystem === "imperial") {
        dispatch(setHeight(values.heightFeet! * 12 + values.heightInches!));
        dispatch(setWeight(values.weight!));
      } else {
        dispatch(setHeight(centimetersToInches(values.heightCM!)));
        dispatch(setWeight(kilosToPounds(values.weight!)));
      }
      dispatch(setAge(values.age!));
      survey.nextPage();
    },
  });

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 1 }}>
        Height, Weight, and Age
      </Typography>
      <Typography variant="body1" sx={{ mb: 4 }}>
        Your trainer will use this information to personalize your plan.
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          {weightSystem === "imperial" ? (
            <>
              <Grid size={6}>
                <TextFieldSelector
                  value={formik.values.heightFeet ?? ""}
                  name="heightFeet"
                  label="ft."
                  options={{
                    max: 7,
                    min: 4,
                    select: isPhone,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.heightFeet &&
                    Boolean(formik.errors.heightFeet)
                  }
                  helperText={
                    formik.touched.heightFeet && formik.errors.heightFeet
                  }
                />
              </Grid>
              <Grid size={6}>
                <TextFieldSelector
                  value={formik.values.heightInches ?? ""}
                  name="heightInches"
                  label="in."
                  options={{
                    max: 11,
                    min: 0,
                    select: isPhone,
                  }}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.heightInches &&
                    Boolean(formik.errors.heightInches)
                  }
                  helperText={
                    formik.touched.heightInches && formik.errors.heightInches
                  }
                />
              </Grid>
            </>
          ) : (
            <Grid size={12}>
              <TextFieldSelector
                value={formik.values.heightCM ?? ""}
                name="heightCM"
                label="cm"
                options={{
                  max: 230,
                  min: 120,
                  select: isPhone,
                }}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                error={
                  formik.touched.heightCM && Boolean(formik.errors.heightCM)
                }
                helperText={formik.touched.heightCM && formik.errors.heightCM}
              />
            </Grid>
          )}
          <Grid size={12}>
            <TextFieldSelector
              value={formik.values.weight ?? ""}
              name="weight"
              label={weightSystem === "imperial" ? "lbs." : "kg"}
              options={
                weightSystem === "imperial"
                  ? {
                      max: 500,
                      min: 50,
                      select: isPhone,
                    }
                  : {
                      max: 225,
                      min: 20,
                      select: isPhone,
                    }
              }
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.weight && Boolean(formik.errors.weight)}
              helperText={formik.touched.weight && formik.errors.weight}
            />
          </Grid>
          <Grid size={12}>
            <TextFieldSelector
              value={formik.values.age ?? ""}
              name="age"
              label="age"
              options={{
                max: 120,
                min: 13,
                select: isPhone,
              }}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              error={formik.touched.age && Boolean(formik.errors.age)}
              helperText={formik.touched.age && formik.errors.age}
            />
          </Grid>
        </Grid>
        <CTAButton CTAText={"Next"} disabled={!formik.isValid} submit />
      </form>
    </Container>
  );
}
