import { Box, Container, Typography } from "@mui/material";
import React from "react";
import { useSurvey } from "src/components/Survey";
import CTAButton from "../Layout/CTAButton";

interface Props {
  title: React.ReactNode;
  content: React.ReactNode;
  testimonialCopy: string;
  testimonialClientName: string;
  testimonialClientDescription: string;
  testimonialClientHeadshotUrl: string;
}

export default function TestimonialSnippet({
  title,
  content,
  testimonialCopy,
  testimonialClientName,
  testimonialClientDescription,
  testimonialClientHeadshotUrl,
}: Props) {
  const survey = useSurvey();

  function clickedNext() {
    survey.nextPage();
  }

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: 2,
          "& b": { color: (theme) => theme.palette.primary.main },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          mb: 2,
          "& b": { color: (theme) => theme.palette.primary.main },
        }}
      >
        {content}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          border: "#DDD solid 1px",
          borderRadius: "16px",
          py: "16px",
          px: "32px",
          background: "#EDF6FD",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <img src={"/assets/purple-5-stars.svg"} style={{ width: "135px" }} />
        <Typography>{testimonialCopy}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <img
            src={testimonialClientHeadshotUrl}
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              objectFit: "cover",
            }}
          />
          <Typography sx={{ fontWeight: "bold" }}>
            -{testimonialClientName}
          </Typography>
          <Typography sx={{ marginTop: "4px" }} variant="caption">
            {testimonialClientDescription}
          </Typography>
        </Box>
      </Box>
      <CTAButton onClick={clickedNext} CTAText={"Continue"}></CTAButton>
    </Container>
  );
}
