import Snippet from "./Snippet";

export default function SnippetContainer() {
  return (
    <Snippet
      title={"An expert trainer in your corner"}
      content={
        <>
          Fewer than <b>3%</b> of trainers make the cut to work with us -
          we&apos;ll find the perfect match for you.
        </>
      }
      imageSrc={"/assets/copilot-welcome.svg"}
    />
  );
}
