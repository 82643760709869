import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Box, Typography } from "@mui/material";

interface Props {
  title: string;
  content: string;
  color?: string;
}

export default function CheckboxFeature({ title, content, color }: Props) {
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start" }}>
      <CheckCircleOutlineRoundedIcon fontSize="large" sx={{ color: color }} />
      <Box sx={{ flex: 1, ml: 3 }}>
        <Typography variant="h4" sx={{ mb: 1 }}>
          {title}
        </Typography>
        <Typography>{content}</Typography>
      </Box>
    </Box>
  );
}
