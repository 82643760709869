import type { PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Client, OrganizationLocation } from "@trainwell/features/legacy";
import { identify, setUserProp } from "src/lib/btracking";
import { api } from "src/lib/trainwellApi";
import {
  setActiveTestIds,
  smartRecoverEvent,
  trackEvent,
} from "./analyticsSlice";
import {
  fetchSubscriptionGroup,
  selectSubscriptionOptions,
  selectedPlan,
} from "./paymentSlice";
import type { RootState } from "./store";

export const persistSurveyData = createAsyncThunk(
  "client/persistSurveyData",
  async (_, { getState }) => {
    const state = getState() as RootState;
    console.log("Redux: Persisting client survey data to db");

    if (!state.client.clientID)
      throw new Error("Unable to persist survey without client ID.");

    await api.clients.persistSurveyData(state.client.clientID, {
      height: state.survey.height,
      weight: state.survey.weight,
      gender: state.survey.gender,
      weightSystem: state.survey.preferred_weight_system,
      age: state.survey.age,
      ownsSmartWatch: state.survey.ownsSmartWatch,
      preCopilotWorkoutFrequency: state.survey.workoutFrequency,
      preCopilotEquipment: state.survey.workoutEquipment,
      preCopilotActivityFrequency: state.survey.activityFrequency,
      preCopilotTrainerExperience: state.survey.trainerExperience,
      tagsSpecialties: state.survey.tagSpecializations,
      tagsIdentity: state.survey.tagDemographics,
      tagsExperience: state.survey.tagExperience,
      tagsCommunicationStyle: state.survey.tagCommunicationStyle,
      tagsObWindowsPreferred: state.survey.tagObWindowsPreferred,
      tagsGoals: state.survey.tagGoals,
      tagsAddOns: state.survey.tagAddOns,
      devicePlatform: state.survey.chosenPhoneOS,
      organizationLocationId: state.survey.organization_location_id,
    });
  },
);

export const setupClient = createAsyncThunk(
  "client/setupClient",
  async (freemium: boolean | undefined, { getState, dispatch }) => {
    const state = getState() as RootState;
    console.log("Redux: Setting up client");

    const subOptions = selectSubscriptionOptions(state);
    const selectedOption = selectedPlan(state);

    if (
      !state.client.clientID ||
      !state.survey.firstName ||
      !state.survey.lastName ||
      !state.survey.email ||
      !state.survey.gender ||
      !state.survey.chosenPhoneOS ||
      !state.survey.phoneNumber ||
      !subOptions
    ) {
      throw new Error(
        `Missing information on client setup, \n
        phoneNumber: ${state.survey.phoneNumber ?? "undefined"}\n
        subOptions: ${JSON.stringify(subOptions) ?? "undefined"}\n
        user_id: ${state.client.clientID ?? "undefined"}\n
        firstName: ${state.survey.firstName ?? "undefined"}\n
        lastName: ${state.survey.lastName ?? "undefined"}\n
        email: ${state.survey.email ?? "undefined"}\n
        gender: ${state.survey.gender ?? "undefined"}\n
        chosenPhoneOs: ${state.survey.chosenPhoneOS ?? "undefined"}
        `,
      );
    }

    const url = new URL(location.href);

    const payload = {
      firstName: state.survey.firstName,
      lastName: state.survey.lastName,
      email: state.survey.email,
      phoneNumber: state.survey.phoneNumber,
      height: state.survey.height,
      weight: state.survey.weight,
      gender: state.survey.gender,
      weightSystem: state.survey.preferred_weight_system,
      age: state.survey.age,
      sourceId: state.analytics.sourceID ?? undefined,
      ownsSmartWatch: state.survey.ownsSmartWatch,
      defaultTimezoneOffset: new Date().getTimezoneOffset() * -60,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      preCopilotWorkoutFrequency: state.survey.workoutFrequency,
      preCopilotEquipment: state.survey.workoutEquipment,
      preCopilotActivityFrequency: state.survey.activityFrequency,
      preCopilotTrainerExperience: state.survey.trainerExperience,
      brand: state.analytics.brand,
      monthlyPrice: subOptions[selectedOption].monthly_price,
      sessionId: state.analytics.sessionID ?? "none",
      utmSource: state.analytics.utm.utmSource,
      utmCampaign: state.analytics.utm.utmCampaign,
      utmContent: state.analytics.utm.utmContent,
      utmMedium: state.analytics.utm.utmMedium,
      tagsSpecialties: state.survey.tagSpecializations,
      tagsIdentity: state.survey.tagDemographics,
      tagsExperience: state.survey.tagExperience,
      tagsCommunicationStyle: state.survey.tagCommunicationStyle,
      tagsObWindowsPreferred: state.survey.tagObWindowsPreferred,
      tagsGoals: state.survey.tagGoals,
      tagsAddOns: state.survey.tagAddOns,
      devicePlatform: state.survey.chosenPhoneOS,
      url: "https://" + url.hostname + "/?" + url.searchParams.toString(),
      freemium: freemium ?? false,
    };

    const response = await api.clients.setup(state.client.clientID, payload);

    if (!response) {
      throw new Error(
        "setupClient endpoint did not return any data when a response was expected",
      );
    }

    if (response.exists === undefined || response.exists === false) {
      if (!state.payment.isFree && !state.payment.forceNoCard) {
        dispatch(smartRecoverEvent("lead"));
      }

      dispatch(
        trackEvent({
          event_type: "create_account",
        }),
      );

      identify(state.client.clientID);
      setUserProp({ status: "lead" });
    }

    if (response.tests) {
      dispatch(setActiveTestIds(response.tests));
      dispatch(fetchSubscriptionGroup(response.user_id));
    }

    return response;
  },
);

export const loadLocations = createAsyncThunk(
  "client/loadLocations",
  async (organizationId: string) => {
    return await api.organizations.getLocations(organizationId);
  },
);

// Define a type for the slice state
interface UserState {
  clientState?: Client["account"]["membership"]["state"];
  clientID: string | undefined;
  client: Client | undefined;
  errors: {
    submitSurveyError: string | SerializedError | undefined;
  };
  submitSurveyStatus:
    | "idle"
    | "loading"
    | "succeeded"
    | "succeeded-info-exists"
    | "failed";
  submitSurveyError: string | undefined;
  /** Used to pair a SINGLE-USE influencer link with an influencer object */
  pairInfluencerUserId: string | undefined;
  locations?: OrganizationLocation[];
}

// Define the initial state using that type
const initialState: UserState = {
  submitSurveyStatus: "idle",
  submitSurveyError: undefined,
  clientID: undefined,
  client: undefined,
  errors: {
    submitSurveyError: undefined,
  },
  pairInfluencerUserId: undefined,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetClient: () => initialState,
    setClientID: (state, action: PayloadAction<string>) => {
      state.clientID = action.payload;
    },
    setClient: (state, action: PayloadAction<Client>) => {
      state.client = action.payload;
    },
    setClientState: (
      state,
      action: PayloadAction<
        Client["account"]["membership"]["state"] | undefined
      >,
    ) => {
      state.clientState = action.payload;
    },
    setPairInfuencerUserId: (state, action: PayloadAction<string>) => {
      state.pairInfluencerUserId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setupClient.pending, (state) => {
      state.submitSurveyStatus = "loading";
    });
    builder.addCase(setupClient.fulfilled, (state, action) => {
      console.log("Redux: Submitted survey");

      const { user_id, exists, state: clientState } = action.payload;

      state.clientID = user_id;

      if (exists) {
        state.submitSurveyStatus = "succeeded-info-exists";
        state.clientState = clientState;
      } else {
        state.submitSurveyStatus = "succeeded";
      }
    });
    builder.addCase(setupClient.rejected, (state, action) => {
      state.submitSurveyStatus = "failed";
      state.submitSurveyError = action.error.message;
      state.errors.submitSurveyError = action.error.message;
    });
    builder.addCase(loadLocations.fulfilled, (state, action) => {
      state.locations = action.payload.locations;
    });
    builder.addCase(loadLocations.rejected, (state) => {
      state.locations = [];
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  resetClient,
  setClientID,
  setClient,
  setClientState,
  setPairInfuencerUserId,
} = clientSlice.actions;

export default clientSlice.reducer;

export const selectUserId = (state: RootState) => {
  return state.client.clientID;
};

export const selectSurveyStatus = (state: RootState) => {
  return state.client.submitSurveyStatus;
};

export const selectLocations = (state: RootState) => {
  return state.client.locations;
};
