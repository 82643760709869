import type { TagExperience } from "@trainwell/features/legacy";
import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { track } from "src/lib/btracking";
import { selectTagExperience } from "src/slices/surveySlice";
import Tags from "./components/Tags";
import TagsPageLayout from "./components/TagsPageLayout";
import { experienceData } from "./data/experienceData";

export default function TagExperiencePage() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const tags = useAppSelector((state) => state.survey.tagExperience);

  function selectTag(tag: string) {
    dispatch(selectTagExperience(tag as TagExperience));
  }

  const onClick = () => {
    track("Experience Select", { tags: tags.length > 0 ? tags : ["empty"] });
    nextPage();
  };

  return (
    <TagsPageLayout
      title={`Special Trainer Experience`}
      subtitle={`Select any that you feel are important for your trainer to have experience
      in.`}
      onContinue={onClick}
      subText="Don't see your specific need? Chances are we've helped people like you, but our trainers have more specialties than we can list! Email support@trainwell.net and we'll match you with someone who understands your needs! :)"
    >
      <Tags tags={experienceData} onTagClick={selectTag} selected={tags} />
    </TagsPageLayout>
  );
}
