import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import { Box, Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSurvey } from "src/components/Survey";
import { useAppSelector } from "src/hooks/stateHooks";
import { api } from "src/lib/trainwellApi";
import { selectActiveTests } from "src/slices/analyticsSlice";

export default function Refer() {
  const userId = useAppSelector((state) => state.client.clientID);
  const survey = useSurvey();
  const [copied, setCopied] = useState(false);
  const testIds = useAppSelector(selectActiveTests);

  useEffect(() => {
    if (!testIds.includes("refer_in_ob_treatment")) {
      survey.nextPage();
    }
  }, []);

  return (
    <Container maxWidth="xs">
      <Typography variant="h3" sx={{ mb: 1 }}>
        Give 50% off - get 50% off
      </Typography>
      <Typography variant="subtitle1" sx={{ mb: 2 }}>
        Share this link with your friends! When they complete their free trial,
        you'll both get 50% off your next month!
      </Typography>
      <Button
        color={copied ? "success" : "primary"}
        startIcon={copied ? <CheckRoundedIcon /> : <LinkRoundedIcon />}
        variant="text"
        onClick={() => {
          if (!userId) {
            return;
          }

          api.clients
            .createReferral({
              platform: "onboarding",
              referral_medium: "copy_to_clipboard",
              user_id: userId,
            })
            .then((res) => {
              navigator.clipboard.writeText(res.short_url);

              setCopied(true);

              setTimeout(() => {
                setCopied(false);
              }, 3000);
            });
        }}
      >
        {copied ? "Link coped" : "Copy referral link"}
      </Button>
      <img src="/assets/gift-dots.png" width={"100%"} />
      <Box
        sx={{
          mt: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src="/assets/people-circles.png" width={60} />
        <Typography sx={{ ml: 1, fontSize: 14 }}>
          loved by <b>45,000+</b> members
        </Typography>
      </Box>
      <Button
        fullWidth
        onClick={() => {
          survey.nextPage();
        }}
        sx={{
          mt: 4,
        }}
      >
        Continue
      </Button>
    </Container>
  );
}
