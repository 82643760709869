import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import CTAButton from "../Layout/CTAButton";

interface CheckboxFeatureProps {
  item: { title: string; body: string; highlightBody?: string | undefined };
}

function CheckboxFeature({ item }: CheckboxFeatureProps) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
      <CheckCircleOutlineRoundedIcon
        fontSize="large"
        sx={{ color: (theme) => theme.palette.success.main }}
      />
      <Box sx={{ flex: 1, ml: 2 }}>
        <Typography fontWeight={"bold"} fontSize={17}>
          {item.title}
        </Typography>
        <Typography fontSize={17}>{item.body}</Typography>
        {item.highlightBody && (
          <Typography fontSize={17} sx={{ textDecoration: "underline" }}>
            {item.highlightBody}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

interface PlanDisplayProps {
  displayCopy: {
    title: React.ReactNode;
    checkBoxItems: {
      title: string;
      body: string;
      highlightBody?: string | undefined;
    }[];
    monthlyPrice: string;
    buttonCopy: string;
  };
  onClickNext: () => void;
}

export default function Plan({ displayCopy, onClickNext }: PlanDisplayProps) {
  const { title, checkBoxItems, buttonCopy } = displayCopy;

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          mb: { xs: 4, sm: 6 },
          textAlign: "center",
        }}
      >
        {title}
      </Typography>

      {checkBoxItems.map((item, index) => (
        <CheckboxFeature item={item} key={index} />
      ))}

      <CTAButton onClick={onClickNext} CTAText={buttonCopy} />
    </Container>
  );
}
