export const GA_TRACKING_ID = import.meta.env.VITE_GA_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (path: string) => {
  window.gtag("set", "page", path);
  // @ts-expect-error
  window.gtag("send", "pageview");
};

export const getGAClientID = () =>
  new Promise<string | undefined>((resolve, reject) => {
    const timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      reject("gtag took too long");
    }, 1500);
    window.gtag("get", GA_TRACKING_ID, "client_id", (string) => {
      clearTimeout(timer);
      resolve(string as any);
    });
  });

const GTAG_CONVERSION_EVENTS = {
  PURCHASE: "AW-328632147/7rN8CL-Lm9cYENOO2pwB",
  SIGN_UP: "AW-328632147/aCS_COK6m9cYENOO2pwB",
};

interface GTagConversionOptions {
  conversionKey: keyof typeof GTAG_CONVERSION_EVENTS;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export const logGTagConversion = ({
  conversionKey,
  ...params
}: GTagConversionOptions) => {
  if (typeof window === "undefined" && !window) return;
  if (!("gtag" in window)) return;
  try {
    const destination = GTAG_CONVERSION_EVENTS[conversionKey];
    const options = {
      send_to: destination,
      ...params,
    };
    window.gtag("event", "conversion", options);
  } catch (err) {
    console.error(`Failed to logGTagConversion with err:`, err);
  }
};

export const setGTagUserParams = ({
  email,
  firstName,
  lastName,
}: {
  email?: string;
  firstName?: string;
  lastName?: string;
}) => {
  if (typeof window === "undefined" && !window) return;
  if (!("gtag" in window)) return;
  try {
    window.gtag("set", "user_data", {
      email: email?.toLowerCase(),
      first_name: firstName?.toLowerCase(),
      last_name: lastName?.toLowerCase(),
    });
  } catch (err) {
    console.error(`Failed to setGTagUserParams with err:`, err);
  }
};
