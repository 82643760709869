export function getCookie(name: string) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(";");
  for (let c of ca) {
    while (c.startsWith(" ")) c = c.substring(1, c.length);
    if (c.startsWith(nameEQ)) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function setCookie(name: string, value: any) {
  const seconds = 60 * 60 * 24 * 365 * 10;
  document.cookie =
    name +
    "=" +
    (value || "") +
    "; Max-Age=" +
    seconds +
    "; domain=trainwell.net; Path=/";
}
