import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";

const isDevEnv = import.meta.env.VITE_ENV === "DEV";

mixpanel.init(import.meta.env.VITE_MIXPANEL_TOKEN, {
  debug: isDevEnv,
  api_host: import.meta.env.VITE_MP_URL,
  ignore_dnt: true,
});

export const registerQueryParams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const source_id = urlParams.get("source");
  const utm_medium = urlParams.get("utm_medium");
  const utm_source = urlParams.get("utm_source");
  const utm_campaign = urlParams.get("utm_campaign");
  const utm_content = urlParams.get("utm_content");
  const param_brand = urlParams.get("brand");

  // Registers the most recent utm tags as mixpanel only registers the first touch
  registerProp({
    sourceId: source_id,
    utm_source_lt: utm_source ?? "",
    utm_medium_lt: utm_medium ?? "",
    utm_campaign_lt: utm_campaign ?? "",
    utm_content_lt: utm_content ?? "",
  });
  // Registers the first touch utm tags in the user object
  setUserPropOnce({
    sourceId: source_id,
    utm_source_ft: utm_source ?? "",
    utm_medium_ft: utm_medium ?? "",
    utm_campaign_ft: utm_campaign ?? "",
    utm_content_ft: utm_content ?? "",
  });
  // registers the last touch utm tags in the user object
  setUserProp({
    sourceId_lt: source_id,
    utm_source_lt: utm_source ?? "",
    utm_medium_lt: utm_medium ?? "",
    utm_campaign_lt: utm_campaign ?? "",
    utm_content_lt: utm_content ?? "",
  });

  if (
    param_brand === "af" ||
    param_brand === "echelon" ||
    param_brand === "gf"
  ) {
    registerProp({ brand: param_brand });
  }
};

export const registerProp = (props = {}) => {
  mixpanel.register(props);
};
export const registerPropOnce = (props = {}) => {
  mixpanel.register_once(props);
};

export const setUserProp = (props = {}) => {
  mixpanel.people.set(props);
};
export const setUserPropOnce = (props = {}) => {
  mixpanel.people.set_once(props);
};

export const identify = (userId: string) => {
  mixpanel.identify(userId);
  Sentry.setUser({ id: userId });
};

export const track = (eventName: string, properties = {}) => {
  mixpanel.track(eventName, {
    path: location.pathname,
    hostname: window.location.hostname,
    ...properties,
  });
};
export const trackLink = (
  element: object,
  eventName: string,
  properties = {},
) =>
  // @ts-expect-error
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  mixpanel.track_link(element, eventName, {
    path: location.pathname,
    hostname: window.location.hostname,
    ...properties,
  });

export const trackPageView = () => {
  track("Page View");
};
export const enrollInTest = (testName: string, variant: string) => {
  track("$experiment_started", {
    "Experiment name": testName,
    "Variant name": variant,
  });
};

export const trackSelect = (properties: {
  type: string;
  tagSelected: string;
  value?: number;
}) => {
  track("Preference Select", properties);
};
export const trackUnselect = (properties: {
  type: string;
  tagSelected: string;
}) => {
  track("Preference Unselect", properties);
};
/**
 *
 * TAKEN FROM MIXPANEL
 */
export const getClientOS = function (a: string) {
  if (/Windows/i.test(a)) {
    if (a.includes("Phone") || a.includes("WPDesktop")) {
      return "Windows Phone";
    }
    return "Windows";
  } else if (/(iPhone|iPad|iPod)/.test(a)) {
    return "iOS";
  } else if (a.includes("Android")) {
    return "Android";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(a)) {
    return "BlackBerry";
  } else if (/Mac/i.test(a)) {
    return "Mac OS X";
  } else if (a.includes("Linux")) {
    return "Linux";
  } else if (a.includes("CrOS")) {
    return "Chrome OS";
  } else {
    return "";
  }
};

const stringIncludes = function (str: string, needle: string) {
  return str.includes(needle);
};

/*
 * ### TAKEN FROM MIXPANEL
 *
 */
export const getBrowser = function (user_agent: string, vendor: string) {
  vendor = vendor || ""; // vendor is undefined for at least IE9
  if (stringIncludes(user_agent, " OPR/")) {
    if (stringIncludes(user_agent, "Mini")) {
      return "Opera Mini";
    }
    return "Opera";
  } else if (/(BlackBerry|PlayBook|BB10)/i.test(user_agent)) {
    return "BlackBerry";
  } else if (
    stringIncludes(user_agent, "IEMobile") ||
    stringIncludes(user_agent, "WPDesktop")
  ) {
    return "Internet Explorer Mobile";
  } else if (stringIncludes(user_agent, "SamsungBrowser/")) {
    // https://developer.samsung.com/internet/user-agent-string-format
    return "Samsung Internet";
  } else if (
    stringIncludes(user_agent, "Edge") ||
    stringIncludes(user_agent, "Edg/")
  ) {
    return "Microsoft Edge";
  } else if (stringIncludes(user_agent, "FBIOS")) {
    return "Facebook Mobile";
  } else if (stringIncludes(user_agent, "Chrome")) {
    return "Chrome";
  } else if (stringIncludes(user_agent, "CriOS")) {
    return "Chrome iOS";
  } else if (
    stringIncludes(user_agent, "UCWEB") ||
    stringIncludes(user_agent, "UCBrowser")
  ) {
    return "UC Browser";
  } else if (stringIncludes(user_agent, "FxiOS")) {
    return "Firefox iOS";
  } else if (stringIncludes(vendor, "Apple")) {
    if (stringIncludes(user_agent, "Mobile")) {
      return "Mobile Safari";
    }
    return "Safari";
  } else if (stringIncludes(user_agent, "Android")) {
    return "Android Mobile";
  } else if (stringIncludes(user_agent, "Konqueror")) {
    return "Konqueror";
  } else if (stringIncludes(user_agent, "Firefox")) {
    return "Firefox";
  } else if (
    stringIncludes(user_agent, "MSIE") ||
    stringIncludes(user_agent, "Trident/")
  ) {
    return "Internet Explorer";
  } else if (stringIncludes(user_agent, "Gecko")) {
    return "Mozilla";
  } else {
    return "";
  }
};
