import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import type { CoachMatch } from "@trainwell/api-sdk";
import { type JSX } from "react";
import { getCoachName } from "src/lib/misc";
import CoachChips from "./CoachChips";

interface Props {
  coach: CoachMatch;
  chipData: { label: string; color: string; icon: JSX.Element }[];
}

export default function CoachInfo_June({ coach, chipData }: Props) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  let rating: string;
  const nReviews = coach.n_reviews ?? 100;
  const scaledReviews = nReviews >= 100 ? Math.round(nReviews / 3) : nReviews;

  if (nReviews % 3 === 0) {
    rating = "4.8";
  } else if (nReviews % 3 === 1) {
    rating = "4.9";
  } else {
    rating = "5.0";
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "start",
          gap: "16px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            position: "relative",
            justifyContent: "start",
            marginTop: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              borderRadius: "87.5px",
              overflow: "hidden",
            }}
          >
            {coach.headshot_url_hd && (
              <img
                src={coach.headshot_url_hd}
                width={isPhone ? 100 : 150}
                height={isPhone ? 100 : 150}
                alt={getCoachName(coach)}
              />
            )}
          </Box>
        </Box>
        <Box sx={{ gap: "8px", display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h2"
            sx={{ fontWeight: "bold", marginBottom: "8px" }}
          >
            {getCoachName(coach)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isPhone ? "column" : "row",
              alignItems: isPhone ? "start" : "center",
              gap: isPhone ? "8px" : "12px",
              justifyContent: "start",
            }}
          >
            <img src="/assets/purple-5-stars.svg" style={{ width: "85px" }} />
            <Typography sx={{ fontWeight: "bold", color: "#443564" }}>
              {rating} - {scaledReviews} reviews
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ my: "8px" }} variant="body1">
        {coach.justification_paragraph}
      </Typography>
      <CoachChips chips={chipData} />
    </Box>
  );
}
