import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  IconButton,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import type { CoachMatch } from "@trainwell/api-sdk";
import React from "react";

interface Props {
  coach: CoachMatch;
}

export default function MeetYourCoach({ coach }: Props) {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        style: "flex",
        width: "100%",
        textAlign: isPhone ? "center" : "start",
        justifyContent: isPhone ? "center" : "start",
      }}
    >
      <Typography // @ts-expect-error
        variant="YTBig"
        sx={{
          fontSize: isPhone ? 32 : 40,
          marginBottom: "30px",
          fontWeight: "bold",
          lineHeight: 1,
          color: "#443564",
        }}
      >
        {`Meet your trainer, ` + coach.first_name + `!`}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: isPhone ? "center" : "start",
          gap: 10,
          marginTop: 16,
        }}
      >
        <img
          src="/assets/stars-yellow.svg"
          style={{
            width: isPhone ? 125 : 175,
            height: "auto",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            background: "#443564",
            paddingLeft: 6,
            paddingRight: 6,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 8,
            gap: 4,
          }}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <Typography // @ts-expect-error
            variant="tinyCaption"
            sx={{ color: "white" }}
          >
            Your perfect match!
          </Typography>
          <IconButton sx={{ padding: 0, margin: 0 }}>
            <InfoIcon fontSize="small" sx={{ color: "white", padding: 0 }} />
          </IconButton>
        </div>
      </div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography
          sx={{ p: 1, maxWidth: 350, background: "#EDF6FD", color: "#443564" }}
        >
          We analyzed thousands of client + trainer matches to find the perfect
          partner for you.
        </Typography>
      </Popover>
    </Box>
  );
}
