import { useSurvey } from "src/components/Survey";
import { useAppDispatch, useAppSelector } from "src/hooks/stateHooks";
import { setTagCommunicationStyle } from "src/slices/surveySlice";
import SliderSet from "./components/Slider";
import TagsPageLayout from "./components/TagsPageLayout";
import communicationData from "./data/communicationData";

export default function TagCommunicationStyle() {
  const dispatch = useAppDispatch();
  const { nextPage } = useSurvey();
  const tags = useAppSelector((state) => state.survey.tagCommunicationStyle);

  const selectSlide = (key: string, value: number) => {
    dispatch(
      setTagCommunicationStyle({
        key: key,
        value: value,
      }),
    );
  };

  return (
    <TagsPageLayout
      title={`Trainer Communication Style`}
      subtitle={`What kind of personality do you look for in a trainer? Select one from
    each row.`}
      onContinue={nextPage}
    >
      <SliderSet
        data={communicationData}
        selectedTags={tags}
        setTag={selectSlide}
      />
    </TagsPageLayout>
  );
}
